import { useStaticQuery, graphql } from "gatsby";

export const useBlogsQuery = () => {
  const { allSanityBlogs } = useStaticQuery(graphql`
    query {
      allSanityBlogs(sort: { fields: blogDate, order: DESC }, limit: 4) {
        nodes {
          blogDate(formatString: "MMMM YYYY")
          category
          shortDesc
          _type
          main {
            title
            slug {
              current
            }
          }
          featuredImage {
            asset {
              fluid {
                src
                sizes
                base64
                aspectRatio
                srcSet
                srcSetWebp
                srcWebp
              }
            }
          }
        }
      }
    }
  `);
  return allSanityBlogs || {};
};
