import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { globalHistory } from "@reach/router";
import Scroll from "react-scroll";
import { BlockZoneFragment, MetaCardFragment } from "../GraphQl/queryFragments"; //eslint-disable-line
import { useAppContext } from "../state";
import SEO from "../components/global/seo";
import {
  ImageTextCarousel,
  BannerProject,
  BlockZone,
} from "../components/pageBlocks";
import { useBlogsQuery } from "../GraphQl/useBlogsQuery";
import { useLocation } from "@reach/router";

const Blogs = ({ data: { page } }) => {
  const { metaDescription, metaTitle, metaKeywords, metaImage } =
    page.meta || {};
  const { pageBlocks, reverseHeader, title } = page.main || {};
  const { featuredImage, category, blogDate } = page || {};
  const { setReverseHeader, setHiddenHeaderFooter } = useAppContext();
  const { nodes } = useBlogsQuery();
  const { pathname } = useLocation();
  const scroll = Scroll.animateScroll;
  const search = globalHistory.location.search;
  const target = parseInt(search.split("?target=")[1]);

  const filteredNodes = nodes.filter((node) => {
    return `/blogs/${node.main.slug.current}` !== pathname;
  });

  useEffect(() => {
    if (target) {
      scroll.scrollTo(target);
    }
    if (reverseHeader === true) {
      setReverseHeader(true);
    } else setReverseHeader(false);
    setHiddenHeaderFooter(false);
  }, [target, scroll, setReverseHeader, reverseHeader, setHiddenHeaderFooter]);

  const spacing = {
    padTop: "170",
    padBot: "170",
  };

  const link = {
    page: {
      main: {
        slug: {
          current: "blogs",
        },
      },
    },
    linkText: "View all",
  };

  return (
    <>
      <SEO
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywords}
        metaImage={metaImage}
      />
      <BannerProject
        title={title}
        image={featuredImage}
        category={category}
        blogDate={blogDate}
      />
      {pageBlocks && <BlockZone zoneBlocks={pageBlocks} />}
      <ImageTextCarousel
        heading="More Blogs"
        spacing={spacing}
        items={filteredNodes}
        isBgImage={false}
        link={link}
      />
    </>
  );
};

export default Blogs;

export const blogsQuery = graphql`
  query blogsQuery($slug: String!) {
    page: sanityBlogs(main: { slug: { current: { eq: $slug } } }) {
      blogDate(formatString: "MMMM, YYYY")
      category
      featuredImage {
        asset {
          fluid(maxWidth: 2000) {
            ...GatsbySanityImageFluid
          }
        }
      }
      main {
        pageBlocks {
          ...BlockZoneFields
        }
        reverseHeader
        title
      }
      meta {
        ...MetaCardFields
      }
    }
  }
`;
