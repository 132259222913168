import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { useInView } from "react-intersection-observer";
import { motion, AnimatePresence } from "framer-motion";

// Components
import { GridContainer, StyledHeading } from "../common";

const StyledBannerProject = styled(GridContainer)`
  .gatsby-image-wrapper {
    z-index: 1;
    grid-column: 1 / span 14;
    grid-row: 1;
    > div {
      background: rgba(0, 0, 0, 0.5);
      padding-bottom: 60vh !important;
    }
  }
  article {
    grid-column: 2 / span 12;
    grid-row: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    align-content: flex-end;
    flex-wrap: wrap;
    z-index: 3;
    h6 {
      text-transform: uppercase;
      margin-bottom: 1rem;
      letter-spacing: 1.3px;
    }
    * {
      width: 100%;
    }
  }
`;

const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.4);
`;

// Animation
const variants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

export const BannerProject = ({ title, image, category, blogDate }) => {
  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: true,
    threshold: 0,
  });

  return (
    <StyledBannerProject ref={ref}>
      <AnimatePresence exitBeforeEnter>
        {image.asset.fluid && <Img fluid={image.asset.fluid} />}
        <Mask />
        <article>
          <motion.div
            animate={inView ? "visible" : "hidden"}
            initial={false}
            variants={variants}
            transition={{
              type: "ease-out",
              damping: 10,
              delay: 0.3,
              duration: 0.5,
            }}
          >
            {category && (
              <StyledHeading as="h6" size="p" white bold fontReg>
                {category} {blogDate && `— ${blogDate}`}
              </StyledHeading>
            )}
            {title && (
              <StyledHeading as="h1" size="h3" white>
                {title}
              </StyledHeading>
            )}
          </motion.div>
        </article>
      </AnimatePresence>
    </StyledBannerProject>
  );
};
